/*
 * NOTE: these styles can only be imported into _app.tsx
 */
@layer zinnia-reset;

html,
body {
  padding: 0;
  margin: 0;
  height: calc(100vh - 50px);

  background-color: #fffdf9; /* neutral-100 */
  font-family: var(--zds-typography-base, Helvetica, arial, sans-serif);
  font-size: 16px;
  font-weight: 400;
  color: #2d2e2e; /* neutral-800 */
}

html {
  box-sizing: border-box;
  font-size: 16px;
  height: 100%;
}

body {
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
pre {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(
    --zds-typography-heading-display,
    Helvetica,
    arial,
    sans-serif
  );
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}

p {
  margin-bottom: 1rem;
}
p:last-child {
  margin-bottom: 0;
}
